import React from 'react';
import './footer.css';

export default class AboutMe extends React.Component {
  render() {
    return (
      <div className='footer' id='Contact'>
        <p> © 2020. All Rights Reserved, designed by Sam Brodersen</p>
      </div>
    );
  }
}
